import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <ul>
      <li parentName="ul">{`UTSC BSc Computer Science '22`}</li>
      <li parentName="ul">{`Full Stack Developer at `}<a parentName="li" {...{
          "href": "https://verto.health"
        }}>{`Verto Health`}</a></li>
    </ul>
    <ul>
      <li parentName="ul">{`Contributed to 4 mobile apps; two of them are `}<a parentName="li" {...{
          "href": "https://apps.apple.com/us/app/verto-flow/id1490892524"
        }}>{`Verto Flow`}</a>{` and `}<a parentName="li" {...{
          "href": "https://apps.apple.com/ca/app/pickeasy/id1507000315"
        }}>{`PickEasy`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/yifeiyin"
        }}>{`GitHub`}</a>{` | `}<a parentName="li" {...{
          "href": "https://linkedin.com/in/yif"
        }}>{`LinkedIn`}</a></li>
    </ul>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "./blog"
        }}>{`old (longer, more formal) blog`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "./quick-notes"
        }}>{`new (shorter, more casual) blog/notes`}</a></li>
    </ul>
    <ul>
      <li parentName="ul">{`PGP Key: `}<a parentName="li" {...{
          "href": "https://keys.openpgp.org/search?q=F57F+0B21+984C+0B12+35D5+D087+CE4E+6606+FE43+4FE3"
        }}><inlineCode parentName="a">{`F57F 0B21 984C 0B12 35D5 D087 CE4E 6606 FE43 4FE3`}</inlineCode></a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      